import { useEffect, useState } from "react"
import { getAllPlanes } from '../helpers/getAllPlanes'

export const useFetchPlanes = () => {
    const [planes, setPlanes] = useState({})

    useEffect(() => {
        getAllPlanes().then(data => {
            setPlanes({ data })
        })
    }, [])

    return planes
}