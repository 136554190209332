import React from 'react'
import './Footer.css'

import logo_cerrito from '../assets/logo_cerrito_blue.png'
import redes1 from '../assets/fb_logo_blue.png'
import redes2 from '../assets/tw_logo_blue.png'
import redes3 from '../assets/ig_logo_blue.png'

export const Footer = () => {
    return (
        <div className='section bg bg-footer'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 col-md-6 contacto'>
                        <p className='footer-title'>
                            Contactos<br/>
                            comercial@fundacionparaguaya.org.py<br/>
                            +595 971 668 218<br/>
                            </p>
                    </div>
                    <div className='col-12 col-md-2 redes'>
                        <nav>
                            <a target="_blank" rel="noreferrer" href='https://www.facebook.com/cerritomarketpy'><img src={redes1} className='social-icon me-1' alt='facebook' /></a>
                            <a target="_blank" rel="noreferrer" href='https://www.twitter.com/'><img src={redes2} className='social-icon me-1' alt='twitter' /></a>
                            <a target="_blank" rel="noreferrer" href='https://www.instagram.com/cerrito.py/'><img src={redes3} className='social-icon me-1' alt='instagram' /></a>
                        </nav>
                    </div>
                    <div className='col-12 col-md-2'></div>
                    <div className='col-12 col-md-2 tienda'>
                        <a target="_blank" rel="noreferrer" href='http://www.cerritopy.com.py/cerrito-gourmet/#/home' style={{'color': 'white', 'textDecoration': 'none'}}>
                            <img src={logo_cerrito} className='logo-cerrito mb-2' alt='logo-cerrito' /><br />
                        </a>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}