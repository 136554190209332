import React from 'react'
import './Section3.css'

import img1 from '../../../assets/se_parte_1_icono.png'
import img2 from '../../../assets/se_parte_2_icono.png'
import { NavLink } from 'react-router-dom'

export const Section3 = () => {
    return (<div className='bg-section-3'>
            <div className='container section'>

                <p className='separte-title text-center mb-5' style={{margin: '0 20px'}}>
                    Ser parte del CLUB DEL QUESO es así de fácil:
                </p>

                <div className='container content-section-3'>

                    <div className='row'>

                        <div className='col-md-12 text-white' style={{marginTop: '100px'}}>
                            <div className='row'>
                                <div className='col-12 col-md-1 col-sm-12 number'>
                                    <p className='number-content-section-3'>1</p>
                                </div>
                                <div className='col-12 col-md-9 col-sm-12 content-box'>
                                    <p className='title-content-section-3 pt-3'>
                                        Completá el formulario, elegí tu plan <br/>y aboná tu suscripción.&nbsp; 
                                        <NavLink className='link-orange' to="/suscribite">AQUí</NavLink>
                                    </p>
                                </div>
                                <div className='col-md-2 img-content-section-3'>
                                <img src={img1} className="d-block" alt="..."/>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12' style={{marginTop: '40px', marginBottom: '40px'}}>&nbsp;</div>

                        <div className='col-md-12 text-white'>
                            <div className='row'>
                                <div className='col-12 col-md-1 col-sm-12 number'>
                                    <p className='number-content-section-3'>2</p>
                                </div>
                                <div className='col-12 col-md-4 col-sm-12 content-box'>
                                    
                                    <div>
                                        <p className='title-content-section-3'>Recibí tu maridaje</p>
                                        <p className='text-content-section-3'>
                                            A partir de la primera entrega temandamos, con un intervalo de 30 días, 
                                            tu caja gourmet del mes a tu casa, oficina o el punto de entrega que nos indiques.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-12 col-md-5 col-sm-12 content-box'></div>
                                <div className='col-md-2 img-content-section-3'>
                                <img src={img2} className="d-block" alt="..." />
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12' style={{marginTop: '100px'}}>&nbsp;</div>

                    </div>

                </div>
            </div>
        </div>
    )
}