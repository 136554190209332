import React from 'react'
import { NavLink } from "react-router-dom";
import logo from '../assets/logo_club_del_queso.png'
import './Navbar.css'

export const Navbar = () => {
    return (
        <header className="relative flex justify-between overflow-visible bg-white custom-bg-header">
            
            <nav className="navbar navbar-expand-lg navbar-dark">

                <div className="container-fluid px-5">

                    {/* <h3 className="navbar-brand fw-bold navbar-title" style={{marginTop: '7px'}}>CLUB <br/><span style={{color: '#e0d0a6'}}>DEL QUESO</span></h3> */}
                    <NavLink to="/">
                        <img className='navbar-brand navbar-logo' src={logo} alt='logo' />
                    </NavLink>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav text-uppercase">
                            <li className="nav-item">
                                <NavLink 
                                    className={({isActive}) => `nav-link ${ isActive ? 'active': ''}`} 
                                    to="/">
                                    Inicio
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink 
                                    className={({isActive}) => `nav-link ${ isActive ? 'active': ''}`} 
                                    to="/conocenos">
                                    Conocenos
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink 
                                    className={({isActive}) => `nav-link ${ isActive ? 'active': ''}`} 
                                    to="/el-impacto">
                                        El impacto
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink 
                                    className={({isActive}) => `nav-link ${ isActive ? 'active': ''}`} 
                                    to="/beneficios">
                                        Beneficios
                                </NavLink>
                            </li>
                            <li className="nav-item item-btn">
                                <NavLink 
                                    className={({isActive}) => `nav-link ${ isActive ? 'active': ''}`} 
                                    to="/suscribite">
                                        Suscribite
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            
        </header>
    )
}