import React from 'react'

import item1 from '../../../src/assets/beneficio_banner_1_2.jpg'
import item2 from '../../../src/assets/beneficio_banner_1_3.jpg'

import './BeneficiosScreen.css'

export const BeneficiosScreen = () => {
    return (
        <div className='container-fluid bg-section-2'>
            <div className='container' style={{paddingTop: '100px'}}>
                <div className='row'>

                    <div className='col-md-12'>
                        <div className='row'>
                        <div className='col-md-12 text-center'>
                            <img src={item1} className='img-beneficio-section' alt='...' />
                        </div>
                        <div className='col-md-12 text-center' style={{margin: '80px 0px'}}>
                            <a target="_blank" rel="noreferrer" href='http://www.hotelcerrito.com.py/' className='btn-beneficio-section'>Ver más</a>
                        </div>
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <div className='row'>
                        <div className='col-md-12 text-center'>
                            <img src={item2} className='img-beneficio-section' alt='...' />
                        </div>
                        <div className='col-md-12 text-center' style={{margin: '80px 0px'}}>
                            <a target="_blank" rel="noreferrer" href='http://www.hotelcerrito.com.py/' className='btn-beneficio-section'>Ver más</a>
                        </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}