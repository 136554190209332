import React from 'react'
import './Section1.css'

import bgCarousel0 from '../../../assets/home_banner_1.png'
import bgCarousel1 from '../../../assets/home_banner_2.png'
import bgCarousel2 from '../../../assets/home_banner_3.png'
import bgCarousel3 from '../../../assets/home_banner_4.png'

export const Section1 = () => {
    return (
        <div>
            <div id="carouselPSHome" className="carousel carousel-home slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselPSHome" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselPSHome" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselPSHome" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselPSHome" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={bgCarousel0} className="d-block w-100" alt="..." />
                        <div className="carousel-caption">
                            {/* <div className='center-text'>
                                <h3 className="font-weight-bold">La membresía de los paladares exigentes</h3>
                            </div> */}
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={bgCarousel1} className="d-block w-100" alt="..." />
                        {/* <div className="carousel-caption d-none d-md-block text-start">
                            <h3 className="font-weight-bold"></h3>
                        </div> */}
                    </div>
                    <div className="carousel-item">
                        <img src={bgCarousel2} className="d-block w-100" alt="..." />
                        {/* <div className="carousel-caption d-none d-md-block text-start">
                            <h3 className="font-weight-bold"></h3>
                        </div> */}
                    </div>
                    <div className="carousel-item">
                        <img
                            src={bgCarousel3}
                            className="d-block w-100"
                            alt="..."
                        />
                        {/* <div className="carousel-caption d-none d-md-block text-start">
                            <h3 className="font-weight-bold"></h3>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}