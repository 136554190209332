import { serverContext } from '../config'
import axios from 'axios'

export const getPersonaByNrodoc = async (nrodoc) => {
    try {
        const { data } = await axios.get(`${serverContext}/api/v1/persona/${nrodoc}`)
        return data
    } catch (error) {
        throw new Error(error)
    }
}