import React from 'react';
import './section1.css';

export const Section1 = () => {
    return (
        <div className='container-fluid second-box'>
            <div className='row'>
                <div className='col-md-12 p-5 img-impacto-section-1-1'>
                    <iframe
                        src='https://www.youtube.com/embed/GxqJXHRo_II?controls=0'
                        title='YouTube video player'
                        frameborder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;'
                        allowfullscreen
                    ></iframe>
                    <div className='box-impacto-section-1 text-center'>
                        Productos gourmet elaborados en<br /> el corazón de Benjamín Aceval
                    </div>
                </div>
            </div>
        </div>
    );
};
