import React from 'react'
import './section2.css'
import { Link } from 'react-router-dom'

export const Section2 = () => {
    return (
        <div className='container-fluid second-box'>
            <div className='row'>
                <div className='col-md-12 p-5 img-section-1-2'></div>
                <div className='col-md-12 p-5'>
                    {/*  */}
                    <div>
                        <div id="carouselPSHome2" className="carousel slide section2-carousel-container" data-bs-ride="carousel2" >
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselPSHome2" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                {/* <button type="button" data-bs-target="#carouselPSHome2" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselPSHome2" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <p className='text-center' style={{'fontSize': '40px'}}>¿Cómo funciona?</p>
                                    <p className='text-start carousel-text'>
                                        Abonando tu suscripción mensual recibí o regalá una vez al mes, en tu casa u oficina, 
                                        una caja sorpresa con los mejores quesos, aperitivos y manjares de Cerrito, 
                                        acompañados de otros productos de marcas destacadas y/o autores del mundo gourmet.
                                    </p>
                                </div>
                                {/* <div className="carousel-item">
                                    <p className='text-start carousel-text'>
                                        
                                    </p>
                                </div>
                                <div className="carousel-item">
                                    <p className='text-start carousel-text'>
                                        
                                    </p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/*  */}
                    <div className='text-center'>
                        <Link to='/suscribite' className='btn btn-info btn-club-queso-2'>
                            SUSCRIBETE AL CLUB DEL QUESO AQUÍ
                        </Link>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}