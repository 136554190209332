import React from 'react'
import { useFetchUpdatePedido } from '../../hooks/useFetchUpdatePedido';

export const ShopScreen = () => {
    const search = window.location.search;
    const query = new URLSearchParams(search)
    const status = query.get('status')
    const isSuccess = status === 'payment_success'
    const idpedido = localStorage.getItem('id_pedido_club_queso')
    const { data: respuesta } = useFetchUpdatePedido(idpedido, isSuccess)
    console.log('respuesta', respuesta)
    return (
        <div>
            <div className="container mt-5 mb-5">

                <p className='mb-5' style={{ 'fontSize': '30px' }}>
                    {isSuccess ? 'PEDIDO FINALIZADO' : 'FINALIZAR PEDIDO'}
                </p>

                <p className='mt-5 mb-5'>
                    {
                        isSuccess
                            ? 'Gracias por realizar su pago. En breve nos comunicaremos con usted.'
                            : 'Ocurrió un problema al realizar el proceso. Favor vuelva a intentar más tarde.'
                    }
                </p>

                <a href='/' className='btn btn-success mt-5'>Volver al sitio principal</a>

            </div>
        </div>
    )
}