import React from 'react'
import './section2.css'

export const Section2 = () => {
    return (
        <div className='container-fluid second-box' style={{paddingBottom: '150px'}}>
            <div className='row'>
                <div className='col-md-12 p-5 img-impacto-section-1-2'></div>
                <div className='col-md-12 p-5'>
                    {/*  */}
                    <div>
                        <div id="carouselPSHome" className="carousel slide section2-carousel-container" data-bs-ride="carousel" >
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselPSHome" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>

                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <p className='text-center text-bold carousel-text'>
                                        <span className='quote-impacto-section-1-2'>"Los alumnos aprenden nuevas prácticas para que el día de mañana ellos puedan hacer algún tipo de emprendimiento con este tipo de productos".</span>
                                        <p className='text-center mt-4 mb-0'>Ricardo Negrette, Maestro Quesero</p>
                                        {/* <p className='text-center'>Alumno de la Escuela Agrícola Cerrito</p> */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>      
    )
}