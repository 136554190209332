import { useEffect, useState } from "react"
import { getPersonaByNrodoc } from '../helpers/getPersonaByNrodoc'

export const useFetchPersonaByNrodoc = (nrodoc) => {

    const [persona, setPersona] = useState({});

    useEffect(() => {
        if (nrodoc.length >= 6) {
            getPersonaByNrodoc(nrodoc).then(data => {
                setPersona({ data })
            })
        }
    }, [nrodoc]);

    return persona

}