import React from 'react'
import './Section2.css'

// import item0 from '../../../assets/mas_icono.png'
import item1 from '../../../assets/queso_icono.png'
import item2 from '../../../assets/mermelada_icono.png'
import item3 from '../../../assets/chacinados_icono.png'
import item4 from '../../../assets/delivery_icono.png'
import item5 from '../../../assets/bebidas_icono.png'

export const Section2 = () => {
    return (<div className='bg-section-2'>
        <div className='container-fluid' style={{backgroundColor: '#c49838'}}>
            <div className='row'>
                <div className='col-12 col-md-5 col-sm-12'>
                    <p className='title bold'>
                        Tu maridaje de <br/>cada mes contiene
                    </p>
                </div>
                <div className='col-12 col-md-7'>
                    <div className='row mt-4'>
                        <div className='col-6 col-md-2 col-sm-6 text-center'>
                            <div className='cont-option'>
                                <p className='text-center'>
                                    <img src={item1} className='cont-img queso' alt='item1' />
                                </p>
                                <p className='cont-text'>Quesos</p>
                            </div>
                        </div>
                        <div className='col-6 col-md-2 col-sm-6 text-center'>
                            <div className='cont-option'>
                                <p className='text-center'>
                                    <img src={item2} className='cont-img mermelada' alt='item2' />
                                </p>
                                <p className='cont-text'>Mermelada</p>
                            </div>
                        </div>
                        <div className='col-6 col-md-2 col-sm-6 text-center'>
                            <div className='cont-option'>
                                <p className='text-center'>
                                    <img src={item5} className='cont-img' alt='item5' />
                                </p>
                                <p className='cont-text'>Bebidas</p>
                            </div>
                        </div>
                        <div className='col-6 col-md-2 col-sm-6 text-center'>
                            <div className='cont-option'>
                                <p className='text-center'>
                                    <img src={item3} className='cont-img' alt='item3' />
                                </p>
                                <p className='cont-text'>Chasinados</p>
                            </div>
                        </div>
                        <div className='col-6 col-md-2 col-sm-6 text-center'>
                            <div className='cont-option'>
                                <p className='text-center'>
                                    <img src={item4} className='cont-img' alt='item4' />
                                </p>
                                <p className='cont-text'>Delivery</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}