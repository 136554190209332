import React from 'react'
import { Section1 } from './section1/section1'
import { Section2 } from './section2/section2'

export const ElimpactoScreen = () => {
    return (
        <div>
            <Section1 />
            <Section2 />
        </div>
    )
}