import { serverContext } from '../config'
import axios from 'axios'

export const postUpdatePedido = async (idpedido, finalizado) => {
    try {
        console.log('helper:', idpedido, finalizado)
        const { data } = await axios.post(`${serverContext}/api/v1/bancard/update`, null, { params: {
            "idpedido": idpedido,
            "finalizado": finalizado
        } })
        console.log('helper update pedido:', data)
        return data
    } catch (error) {
        throw new Error(error)
    }
}