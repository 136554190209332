import { useEffect, useState } from "react"
import { postUpdatePedido } from '../helpers/postUpdatePedido'

export const useFetchUpdatePedido = (idpedido, finalizado) => {
    const [respuesta, setRespuesta] = useState({})
    const [rendered, setRendered] = useState(false);

    useEffect(() => {
        function fetchBusinesses() {
            if (rendered) {
                if (idpedido && finalizado) {
                    postUpdatePedido(idpedido, finalizado).then(data => {
                        console.log('useFetch data', data)
                        setRespuesta({ data })
                    })
                }
            }
    
            if (!rendered) {
                setRendered(true);
            }
        }
        fetchBusinesses()
    }, [rendered])

    return respuesta
}