import './App.css';
import { AppRouter } from './routes/AppRouter';

function App() {
  return (
    <AppRouter />
  );
}

export default App;
