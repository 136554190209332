import { Route, Routes, BrowserRouter } from "react-router-dom"
import { HomeScreen } from "../components/home/HomeScreen"
import { Navbar } from '../shared/Navbar'
import { Footer } from "../shared/Footer"
import { ConocenosScreen } from "../components/conocenos/ConocenosScreen"
import { ElimpactoScreen } from "../components/elimpacto/ElimpactoScreen"
import { SuscribiteScreen } from "../components/suscribite/SuscribiteScreen"
import { BeneficiosScreen } from "../components/beneficios/BeneficiosScreen"
import { PagarScreen } from "../components/pagar/PagarScreen"
import { ShopScreen } from "../components/shop/ShopScreen"

export const AppRouter = () => {
    return (
        // basename={routeName}
        <BrowserRouter>
            <Navbar />
            <div>
                <Routes>
                    <Route path="/" element={<HomeScreen />} />
                    <Route path="/conocenos" element={<ConocenosScreen />} />
                    <Route path="/el-impacto" element={<ElimpactoScreen />} />
                    <Route path="/beneficios" element={<BeneficiosScreen />} />
                    <Route path="/suscribite" element={<SuscribiteScreen />} />
                    <Route path="/pagar/:id" element={<PagarScreen />} />
                    <Route path="/shop/confirm_payment" element={<ShopScreen />} />
                </Routes>
            </div>
            <Footer />
        </BrowserRouter>
    )
}