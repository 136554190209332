import React from 'react'
import './Section4.css'

import item1 from '../../../assets/suscripcion_1_icono.png'
import item2 from '../../../assets/suscripcion_2_icono.png'
import item3 from '../../../assets/suscripcion_3_icono.png'
import item4 from '../../../assets/suscripcion_4_icono.png'

export const Section4 = () => {
    return (
        <div className='container-fluid bg-suscripcion'>

            <div className='container'>

                <div className='row'>

                    <div className='col-md-12 mb-4' style={{marginTop: '120px'}}>
                        <div className='container text-center'>
                            <p className='suscripcion-title-section-2'>Sobre tu suscripción</p>
                        </div>
                    </div>

                    <div className='col-md-12 content-section-4'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className='item-section-4'>
                                    <p className='text-center'><img src={item1} alt="..." style={{width: '180px'}} /></p>
                                    <p className='text-white text-center mt-4 text-content-4'>Planes desde G. 250.000</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='item-section-4'>
                                    <p className='text-center'><img src={item2} alt="..." style={{width: '180px'}} /></p>
                                    <p className='text-white text-center mt-4 text-content-4'>Tu suscripción puede<br/> durar hasta 12 meses</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='item-section-4'>
                                    <p className='text-center'><img src={item3} alt="..." style={{width: '180px'}} /></p>
                                    <p className='text-white text-center mt-4 text-content-4'>Pagá tu suscripción con<br/> cualquier método de pago</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='item-section-4'>
                                    <p className='text-center'><img src={item4} alt="..." style={{width: '180px'}} /></p>
                                    <p className='text-white text-center mt-4 text-content-4'>Disfrutá de beneficios<br/> en comercios adheridos</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}