import { serverContext } from '../config'
import axios from 'axios'

export const getAllPlanes = async () => {
    try {
        const {data} = await axios.get(`${serverContext}/api/v1/plan`)
        return data
    } catch (error) {
        throw new Error(error)
    }
}