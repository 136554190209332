import React from 'react'
import { BancardIframe } from "react-bancard-checkout-js"
import { enviroment } from '../../../config'
import './Sector1.css'

export const Section1 = ({ id }) => {
    const paramId = id;
    return (
        <div>
            <div className='container mt-5 mb-5'>

                <p className='mb-5' style={{ 'fontSize': '30px' }}>COMPLETÁ TU PAGO</p>

                {/* <div style={{ 'width': '100%', 'margin': 'auto' }} id="iframe-container"></div> */}

                <BancardIframe
                    options={{styles: {}}}
                    processId={paramId}
                    enviroment={enviroment}
                />

            </div>

        </div>
    )
}