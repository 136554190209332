import React, { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { PatternFormat } from 'react-number-format'
import { useFetchPersonaByNrodoc } from "../../../hooks/useFetchPersonaByNrodoc"
import { useFetchPlanes } from "../../../hooks/useFetchPlanes"
import { postSingleBuy } from "../../../helpers/postSingleBuy"
import "./Section1.css"

export const Section1 = () => {
    const navigate = useNavigate();
    const [nrodoc, setNrodoc] = useState({})
    const { data } = useFetchPersonaByNrodoc(nrodoc)
    const { data: planes } = useFetchPlanes()
    const handleChange = (e) => {
        const value = e.target.value
        if (value.length >= 6) {
            setNrodoc(e.target.value)
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setInputValue({ ...inputValues, [name]: value })
    }
    const [inputValues, setInputValue] = useState({
        nrodoc: '',
        nombres: '',
        apellidos: '',
        telefono: '',
        email: '',
        plan: '',
        meses: ''
    })
    const [validation, setValidation] = useState({
        nrodoc: '',
        nombres: '',
        apellidos: '',
        telefono: '',
        email: '',
        plan: '',
        meses: ''
    })
    const checkValidation = () => {
        let errors = validation
        let countErrors = 0;
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

        if (!inputValues.nrodoc.trim()) {
            errors.nrodoc = 'Campo requerido'
            countErrors++
        } else {
            errors.nrodoc = ''
        }

        if (!inputValues.nombres.trim()) {
            errors.nombres = 'Campo requerido'
            countErrors++
        } else {
            errors.nombres = ''
        }

        if (!inputValues.apellidos.trim()) {
            errors.apellidos = 'Campo requerido'
            countErrors++
        } else {
            errors.apellidos = ''
        }

        if (!inputValues.telefono.trim()) {
            errors.telefono = 'Campo requerido'
            countErrors++
        } else if (!inputValues.telefono.includes('09')) {
            errors.telefono = 'Numero de teléfono no valido'
            countErrors++
        } else {
            errors.telefono = ''
        }

        if (!inputValues.email.trim()) {
            errors.email = 'Campo requerido'
            countErrors++
        } else if (inputValues.email.match(isValidEmail) === null || !inputValues.email.match(isValidEmail)) {
            errors.email = 'Dirección de correo no valido'
            countErrors++
        } else {
            errors.email = ''
        }

        if (!inputValues.plan.trim()) {
            errors.plan = 'Campo requerido'
            countErrors++
        } else {
            errors.plan = ''
        }

        setValidation(errors)
        return countErrors
    }

    useEffect(() => { checkValidation() }, [inputValues])

    const handleSubmit = async (e) => {
        e.preventDefault()
        // setPedido({})
        if (localStorage.getItem('id_pedido_club_queso')) {
            localStorage.removeItem('id_pedido_club_queso')
            localStorage.removeItem('process_id_club_queso')
        }
        let errors = checkValidation()
        if (errors === 0) {
            const model = {};
            const persona = {};
            persona.idpersona = data?.idpersona || 0
            persona.nrodoc = inputValues.nrodoc
            persona.nombres = inputValues.nombres
            persona.apellidos = inputValues.apellidos
            persona.telefono1 = inputValues.telefono.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '')
            persona.email = inputValues.email
            model.persona = persona
            const selectedPlan = planes.find(e => e.idplanclubqueso === parseInt(inputValues.plan))
            model.plan = selectedPlan
            model.metodopago = "single_buy"
            model.estadopedido = "PENDIENTE"
            model.divisa = "PYG"
            model.subtotal = selectedPlan.monto * parseInt(inputValues.meses)
            model.total = selectedPlan.monto * parseInt(inputValues.meses)
            model.activo = true
            setTimeout(() => {
                // console.log('model', model)
                if (Object.keys(model).length > 0) {
                    postSingleBuy(model).then(data => {
                        console.log('data', data)
                        if (data) {
                            const id_pedido = data[0]
                            const { status, process_id } = JSON.parse(data[1])
                            console.log('idpedido', id_pedido)
                            if (status === 'success') {
                                localStorage.setItem('id_pedido_club_queso', id_pedido)
                                localStorage.setItem('process_id_club_queso', process_id)
                                navigate(`/pagar/${process_id}`)
                            } else {
                                console.log('ocurrió un error al procesar el pedido. Favor intente más tarde')
                                alert('ocurrió un error al procesar el pedido. Favor intente más tarde')
                            }
                        }
                    })
                        .catch(error => alert(error))
                }
            }, 400);

        }
    };
    return (
        <div>
            <div className="container mt-5 mb-5">
                <p style={{ fontSize: "30px" }}>COMPLETÁ TU SUSCRIPCIÓN</p>

                <form>
                    <div className="row">
                        <div className="col-md-4">
                            {/*  */}
                            <div className="form-group">
                                <label>Número de documento</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    name="nrodoc"
                                    onBlur={handleChange}
                                    onKeyUp={handleChange}
                                    onChange={(e) => handleInputChange(e)}
                                    value={inputValues.nrodoc}
                                />
                                {validation.nrodoc && <p className="text-error">{validation.nrodoc}</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            {/*  */}
                            <div className="form-group">
                                <label>Nombres</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="nombres"
                                    required
                                    // defaultValue={data?.nombres || ""}
                                    onChange={(e) => handleInputChange(e)}
                                    value={inputValues.nombres}
                                />
                                {validation.nombres && <p className="text-error">{validation.nombres}</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            {/*  */}
                            <div className="form-group">
                                <label>Apellidos</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    name="apellidos"
                                    // defaultValue={data?.apellidos || ""}
                                    onChange={(e) => handleInputChange(e)}
                                    value={inputValues.apellidos}
                                />
                                {validation.apellidos && <p className="text-error">{validation.apellidos}</p>}
                            </div>
                        </div>
                        <div className="col-md-12">&nbsp;</div>
                        <div className="col-md-12">
                            {/*  */}
                            <div className="form-group">
                                <label>Número de contacto</label>
                                <PatternFormat
                                    format="(####) ### ###"
                                    allowEmptyFormatting
                                    mask="_"
                                    type="text"
                                    className="form-control"
                                    required
                                    name="telefono"
                                    // defaultValue={data?.telefono1 || ""}
                                    onValueChange={(values, sourceInfo) => {
                                        const { event } = sourceInfo
                                        if (event) {
                                            const { nativeEvent } = event
                                            const { target } = nativeEvent
                                            setInputValue({ ...inputValues, [target.name]: target.value });
                                        }
                                    }}
                                    // onChange={(e) => handleInputChange(e)}
                                    value={inputValues.telefono}
                                />
                                {validation.telefono && <p className="text-error">{validation.telefono}</p>}
                            </div>
                        </div>
                        <div className="col-md-12">&nbsp;</div>
                        <div className="col-md-12">
                            {/*  */}
                            <div className="form-group">
                                <label>Correo electrónico</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    required
                                    name="email"
                                    // defaultValue={data?.email || ""}
                                    onChange={(e) => handleInputChange(e)}
                                    value={inputValues.email}
                                />
                                {validation.email && <p className="text-error">{validation.email}</p>}
                            </div>
                            {/*  */}
                        </div>
                        <div className="col-md-12">&nbsp;</div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Datos para la factura: Elegí tu plan</label>
                                <select
                                    className="form-select select-white"
                                    required
                                    name='plan'
                                    onChange={(e) => handleInputChange(e)}
                                >
                                    <option value={null}>Seleccione</option>
                                    {!planes?.length !== 0
                                        ? planes?.map((c) => (
                                            <option key={c.idplanclubqueso} value={c.idplanclubqueso}>
                                                {c.descripcion + " Gs. " + c.monto}
                                            </option>
                                        ))
                                        : []}
                                </select>
                                {validation.plan && <p className="text-error">{validation.plan}</p>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Elegí tu suscripción</label>
                                <select
                                    className="form-select select-white"
                                    required
                                    name='meses'
                                    onChange={(e) => handleInputChange(e)}
                                >
                                    <option value={null}>Seleccione</option>
                                    <option value={1}>1 mes</option>
                                    <option value={3}>3 meses</option>
                                    <option value={6}>6 meses</option>
                                    <option value={12}>12 meses</option>

                                </select>
                                {validation.meses && <p className="text-error">{validation.meses}</p>}
                            </div>
                        </div>
                        <div className="col-md-12">&nbsp;</div>
                        <div className="col-md-12">
                            <div className="form-group">
                                {/* <input type='submit' className='btn btn-success' value='Continuar' /> */}
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={handleSubmit}
                                >
                                    Continuar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
