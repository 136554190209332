import React from 'react'
import { Section1 } from './section1/Section1'
import { useParams } from 'react-router-dom'

export const PagarScreen = () => {
    let { id } = useParams()
    return (
        <div>
            <Section1 id={id} />
        </div>
    )
}