import { serverContext } from '../config'
import axios from 'axios'

export const postSingleBuy = async (pedido) => {
    try {
        const { data } = await axios.post(`${serverContext}/api/v1/bancard/single-buy`, pedido)
        return data
    } catch (error) {
        throw new Error(error)
    }
}