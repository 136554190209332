import React, { useState, useEffect } from 'react'
import './section1.css'

const backgroundImages = [
    '/img/conocenos_banner_1_1.jpg',
    '/img/conocenos_banner_1_2.jpg',
    '/img/conocenos_banner_1_3.jpg'
];

export const Section1 = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleChange = (index) => {
        setCurrentIndex(index);
    }

    useEffect(() => {
        const carouselElement = document.getElementById('carouselPSHome');
        carouselElement.addEventListener('slid.bs.carousel', handleCarouselSlid);
        return () => {
            carouselElement.removeEventListener('slid.bs.carousel', handleCarouselSlid);
        };
    }, []);

    const handleCarouselSlid = (event) => {
        const newIndex = event.to;
        setCurrentIndex(newIndex);
    };

    return (
        <div className='container-fluid second-box'>
            <div className='row'>
                <div className='col-md-12 p-5 img-section-1-1' style={{ backgroundImage: `url(${backgroundImages[currentIndex]})` }}></div>
                <div className='col-md-12 p-5'>
                    {/*  */}
                    <div>
                        <div id="carouselPSHome" className="carousel slide section1-carousel-container" data-bs-ride="carousel" >
                            <div className="carousel-indicators">
                                <button type="button" onClick={() => handleChange(0)} data-bs-target="#carouselPSHome" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" onClick={() => handleChange(1)} data-bs-target="#carouselPSHome" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" onClick={() => handleChange(2)} data-bs-target="#carouselPSHome" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <p className='text-center text-bold carousel-text'>
                                        El Club del Queso es una iniciativa de las Escuelas Agrícolas Autosostenibles de la
                                        Fundación Paraguaya, donde a través de una experiencia culinaria, lúdica y original
                                        buscamos sorprenderte, cada mes, con diferentes propuestas gastronómicas y que
                                        tiene como principal ingrediente, nuestro exquisito Queso Ibérico Cerrito.
                                    </p>
                                </div>
                                <div className="carousel-item">
                                    <p className='text-center text-bold carousel-text'>
                                        Más allá de esto, rendimos homenaje a las manos que cultivan el valor emprendedor de
                                        nuestro modelo educativo y de aquellos artesanos que cautivan lejos de las góndolas
                                        del supermercado.
                                    </p>
                                </div>
                                <div className="carousel-item">
                                    <p className='text-center text-bold carousel-text'>
                                        Por eso, con tu suscripción estás apoyando a productores paraguayos y a la educación de
                                        miles de jóvenes de entornos rurales.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  */}
                    <div className='text-center'>
                        <button className='btn btn-info btn-club-queso-1'>
                            Conocé el modelo educativo autosostenible<br /> de las escuelas aquí
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}